import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import CreatePlan from './CreatePlan'; 
import '../style/ViewPlan.css'; 

const ViewPlan = () => {
  const [plans, setPlans] = useState([]); 
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [isEditing, setIsEditing] = useState(false); 
  const [currentPlan, setCurrentPlan] = useState(null); 
  const [error, setError] = useState('');

  // Fetch plans from the backend
  const fetchData = async () => {
    try {
      const response = await axios.get('https://admin.machaxi.com/summer-api/get-data', {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });
      
      console.log('Fetched Plans Response:', response.data.allPlans);
      console.log('Fetched Timing Response:', response.data.allPlansTiming);
  
      const fetchedPlans = response.data.allPlans.map((plan) => {
        const timingsForPlan = response.data.allPlansTiming.filter(
          (timing) => timing.plan_id === plan.id
        );
  
        return {
          id: plan.id,
          academy_name: plan.academy_name,
          sport_name: plan.sport_name,
          max_avail_arenas: plan.max_avail_arenas,
          price_per_arena: plan.price_per_arena,
          GST: plan.GST,
          timings: timingsForPlan, // Add the timing information here
        };
      });
  
      setPlans(fetchedPlans);
    } catch (error) {
      console.error('Error fetching plans:', error);
      setError('Failed to fetch plans. Please try again.');
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  // Handle deletion of a plan
  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this plan?')) return;

    try {
      const formData = {
        id: id , 
        isDisablePlan : 1, 
        isDisableAddon : 0, 
        isDisableDiscount : 0,
        isDisablePlanTiming : 0, 

      }
      await axios.put(`https://admin.machaxi.com/summer-api/disable-data`, formData, {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg'
        }
      }); 
      setPlans(plans.filter((plan) => plan.id !== id));
      alert('Plan deleted successfully.');
    } catch (error) {
      console.error('Error deleting plan:', error);
      alert('Failed to delete the plan. Please try again.');
    }
  };

  // Handle opening the modal for creating a new plan
  const handleAddMore = () => {
    setCurrentPlan(null); // Reset current plan for new plan
    setIsEditing(false); // Not in editing mode
    setIsModalOpen(true); // Open modal for new plan
  };

  // Handle opening the modal for editing an existing plan
  const handleEdit = (plan) => {
    console.log('Editing plan:', plan); // Debugging
    setCurrentPlan(plan); // Set plan to be edited
    setIsEditing(true); // Enter edit mode
    setIsModalOpen(true); // Open modal for editing
  };

  // Handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false); // Close modal
    setCurrentPlan(null);
    setIsEditing(false);
  };

  // Handle saving a new or edited plan
  const handleSavePlan = (responseData) => {
    fetchData(); // Fetch updated plans after adding or editing
    closeModal(); // Close modal
    alert(isEditing ? 'Plan updated successfully!' : 'Plan created successfully!');
  };

  return (
    <div className="view-plan-container">
      <h2>Sports Center Plans</h2>
      
      {error && <p className="error-message">{error}</p>}

      <div className="button-container">
        <button className="add-more-button" onClick={handleAddMore}>
          Create Plan
        </button>
      </div>

      <table className="plans-table">
        <thead>
          <tr>
            <th>Sport Center</th>
            <th>Sport Type</th>
            <th>Maximum Court</th>
            <th>GST (%)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {plans.map((plan) => (
            <tr key={plan.id} className="plan-row">
              <td>{plan.academy_name}</td>
              <td>{plan.sport_name}</td>
              <td>{plan.max_avail_arenas}</td>
              <td>{plan.GST}%</td>
              <td>
                <button className="edit-button" onClick={() => handleEdit(plan)}>
                  Edit
                </button>
                <button className="delete-button" onClick={() => handleDelete(plan.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
          {plans.length === 0 && (
            <tr>
              <td colSpan="6" className="no-data">
                No plans available. Click "Add More" to create a new plan.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        ariaHideApp={false} // Only for development; set to true in production
        className="modal"
        overlayClassName="overlay"
      >
        <CreatePlan 
          onSave={handleSavePlan} // Pass handleSavePlan to trigger refetch
          onClose={closeModal} // Pass onClose to handle modal closure
          isEditing={isEditing}
          existingPlan={currentPlan}
        />
      </Modal>
    </div>
  );
};

export default ViewPlan;