import React, { useEffect, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import axios from 'axios';
import '../style/CreatePlan.css'; // Import CSS file

const CreatePlan = ({ onSave, onClose, isEditing = false, existingPlan = null }) => {

  const [plan, setPlan] = useState({
    academy_name: '',
    sport_name: '',
    max_avail_arenas: 1,
    price_per_arena: '',
    GST: '',
    id: null // Initialize id as null
  });
  const [sportCenters, setSportCenters] = useState([]); // Unique academy names
  const [error, setError] = useState('');
  const [academySportsMap, setAcademySportsMap] = useState({}); // State to hold the academy-sports mapping
  const [selectedAcademy, setSelectedAcademy] = useState("");
  // State for weekend and weekday data
  const [weekendData, setWeekendData] = useState([{ startTime: '', endTime: '', price: '' }]);
  const [weekdayData, setWeekdayData] = useState([{ startTime: '', endTime: '', price: '' }]);


  // Handle changes in weekend data
  const handleWeekendChange = (index, field, value) => {
    const updatedWeekendData = [...weekendData];
    updatedWeekendData[index][field] = value;
    setWeekendData(updatedWeekendData);
  };

  // Handle changes in weekday data
  const handleWeekdayChange = (index, field, value) => {
    const updatedWeekdayData = [...weekdayData];
    updatedWeekdayData[index][field] = value;
    setWeekdayData(updatedWeekdayData);
  };


  // Populate form fields if editing
  useEffect(() => {
    if (isEditing && existingPlan) {
      console.log('Existing Plan:', existingPlan);  // Check the structure of the plan

      setPlan({
        academy_name: existingPlan.academy_name || '',
        sport_name: existingPlan.sport_name || '',
        max_avail_arenas: existingPlan.max_avail_arenas || 1,
        price_per_arena: existingPlan.price_per_arena || '',
        GST: existingPlan.GST || '',
        id: existingPlan.id || ''
      });

      setSelectedAcademy(existingPlan.academy_name || "");

      // Map weekend and weekday timings to match the form's expected structure
      const weekendTimings = existingPlan.timings
        ?.filter(timing => timing.type === 'Weekend')
        ?.map(timing => ({
          startTime: timing.start_time, // Mapping to form field
          endTime: timing.end_time,     // Mapping to form field
          price: timing.price,          // Mapping to form field
          timing_id: timing.id   // Preserve timing_id
        })) || [];

      const weekdayTimings = existingPlan.timings
        ?.filter(timing => timing.type === 'Weekday')
        ?.map(timing => ({
          startTime: timing.start_time, // Mapping to form field
          endTime: timing.end_time,     // Mapping to form field
          price: timing.price,          // Mapping to form field
          timing_id: timing.id   // Preserve timing_id
        })) || [];

      console.log('Mapped Weekend Timings:', weekendTimings);
      console.log('Mapped Weekday Timings:', weekdayTimings);

      setWeekendData(weekendTimings);
      setWeekdayData(weekdayTimings);
    } else {
      setPlan({
        academy_name: '',
        sport_name: '',
        max_avail_arenas: 1,
        price_per_arena: '',
        GST: '',
        id: null
      });
      setSelectedAcademy("");
      setWeekendData([{ startTime: '', endTime: '', price: '' }]);
      setWeekdayData([{ startTime: '', endTime: '', price: '' }]);
    }
  }, [isEditing, existingPlan]);

  // Fetch academies data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://admin.machaxi.com/summer-api/get-data', {
          headers: {
            'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
          }
        }); // Use a dedicated endpoint

        // Directly access academies from the response data
        const academies = response.data.academies;

        // Check if academies is defined and is an array
        if (!Array.isArray(academies)) {
          throw new Error('Expected academies to be an array');
        }

        // Create a mapping of academy names to sports
        const academySportsMap = {};
        academies.forEach(item => {
          const { academy_name, sport_name } = item;
          if (!academySportsMap[academy_name]) {
            academySportsMap[academy_name] = [];
          }
          academySportsMap[academy_name].push(sport_name);
        });

        const centers = Object.keys(academySportsMap);
        setSportCenters(centers);
        setAcademySportsMap(academySportsMap);
      } catch (error) {
        console.error('Error fetching academies:', error);
        setError('Failed to fetch academies. Please try again.');
      }
    };

    fetchData();
  }, []);

  // Handle changes in the plan fields
  const handlePlanChange = (field, value) => {
    const updatedPlan = { ...plan, [field]: value };
    // Reset sport_name if academy_name changes
    if (field === 'academy_name') {
      updatedPlan.sport_name = '';
      setSelectedAcademy(value);
    }
    setPlan(updatedPlan);
  };
  // Handle adding a new row to weekday data
  const addWeekendRow = () => {
    setWeekendData([...weekendData, { startTime: '', endTime: '', price: '', timing_id: null }]);
  };

  const addWeekdayRow = () => {
    setWeekdayData([...weekdayData, { startTime: '', endTime: '', price: '', timing_id: null }]);
  };

  // Handle removing a row from weekend data
  const removeWeekendRow = async (index) => {
    if (weekendData.length > 1) {
      const rowToRemove = weekendData[index];
      const timingId = rowToRemove.timing_id; // Extract timing_id from the row
      console.log("timind id", timingId)
      if (timingId) {
        const formData = {
          id: timingId, // Use timing_id as the id
          isDisablePlan: 0,
          isDisableAddon: 0,
          isDisableDiscount: 0,
          isDisablePlanTiming: 1,
        };
        console.log("formatdata", formData)
        try {
          // Call the API to disable the timing
          const response = await axios.put(
            'https://admin.machaxi.com/summer-api/disable-data',
            formData,
            {
              headers: {
                'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg',
              },
            }
          );

          if (response.status === 200) {
            // If the API call is successful, remove the row from the state
            const updatedWeekendData = weekendData.filter((_, i) => i !== index);
            setWeekendData(updatedWeekendData);
            alert("Weekday timing disabled successfully")
            console.log('Weekend timing disabled successfully:', response.data);
          } else {
            alert("Failed to disable weekend timing")
            console.error('Failed to disable weekend timing:', response.data);
          }
        } catch (error) {
          console.error('Error disabling weekend timing:', error);
        }
      } else {
        // If there's no timing_id, just remove the row from the state
        const updatedWeekendData = weekendData.filter((_, i) => i !== index);
        setWeekendData(updatedWeekendData);
      }
    }
  };

  const removeWeekdayRow = async (index) => {
    if (weekdayData.length > 1) {
      const rowToRemove = weekdayData[index];
      const timingId = rowToRemove.timing_id; // Extract timing_id from the row

      if (timingId) {
        const formData = {
          id: timingId, // Use timing_id as the id
          isDisablePlan: 0,
          isDisableAddon: 0,
          isDisableDiscount: 0,
          isDisablePlanTiming: 1,
        };

        try {
          // Call the API to disable the timing
          const response = await axios.put(
            'https://admin.machaxi.com/summer-api/disable-data',
            formData,
            {
              headers: {
                'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg',
              },
            }
          );

          if (response.status === 200) {
            const updatedWeekdayData = weekdayData.filter((_, i) => i !== index);
            setWeekdayData(updatedWeekdayData);
            alert("Weekday timing disabled successfully")
            console.log('Weekday timing disabled successfully:', response.data);
          } else {
            alert("Failed to disable weekday timing")
            console.error('Failed to disable weekday timing:', response.data);
          }
        } catch (error) {
          console.error('Error disabling weekday timing:', error);
        }
      } else {
        // If there's no timing_id, just remove the row from the state
        const updatedWeekdayData = weekdayData.filter((_, i) => i !== index);
        setWeekdayData(updatedWeekdayData);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the form submission from reloading the page

    // Function to map timings from the existing plan or new data
    const mapTimings = (data, type) => {
      return data
        .filter(row => row.startTime && row.endTime) // Filter out invalid entries
        .map((row) => {
          const timing = {
            start_time: row.startTime,
            end_time: row.endTime,
            price: row.price, // Ensure price is a number or string as needed
            type: type,       // Set type dynamically based on the category (Weekend/Weekday)
            is_enabled: 1,    // Defaulting to enabled
          };

          if (row.timing_id) {
            timing.timing_id = row.timing_id; // If there's an existing timing_id, include it
          } else {
            timing.timing_id = null; // For new timings, timing_id should be null
          }

          console.log('Mapped Timing:', timing); // Console log for each mapped timing
          return timing;
        });
    };

    // Prepare weekend and weekday timings
    const weekendTimings = mapTimings(weekendData, 'Weekend');
    const weekdayTimings = mapTimings(weekdayData, 'Weekday');

    console.log('Weekend Timings:', weekendTimings); // Log weekend timings
    console.log('Weekday Timings:', weekdayTimings); // Log weekday timings

    // Create the payload with all updated data
    const payload = {
      isCreatePlan: isEditing ? 0 : 1, // Set to 1 for new plan, 0 for editing
      isEditPlan: isEditing ? 1 : 0,   // Set to 1 for editing, 0 for new plan
      academy_name: plan.academy_name,
      sport_name: plan.sport_name,
      max_avail_arenas: parseInt(plan.max_avail_arenas, 10),
      timings: [...weekendTimings, ...weekdayTimings], // Include the updated timings
      GST: parseFloat(plan.GST) // GST as a number
    };

    // If editing, include the plan ID
    if (isEditing && existingPlan) {
      payload.id = existingPlan.id;
    }

    console.log('Final Payload:', payload); // Log the final payload

    // Send the request to the API for updating the existing plan
    try {
      const response = await axios.post('https://admin.machaxi.com/summer-api/admin-post-data', payload, {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });

      console.log('Response:', response.data); // Log the API response

      if (response.status === 200) {
        console.log(isEditing ? 'Plan updated successfully!' : 'Plan created successfully!');
        onClose();
        alert(isEditing ? "Plan updated successfully!" : "Plan created successfully!"); // Trigger callback to refresh view or update state
        // Optionally, handle successful response (e.g., redirect, show a success message)
      } else {
        console.error(isEditing ? 'Failed to update plan:' : 'Failed to create plan:', response.data);
        setError(isEditing ? 'Failed to update plan. Please try again.' : 'Failed to create plan. Please try again.');
      }

    } catch (error) {
      console.error(isEditing ? 'Error updating the plan:' : 'Error creating the plan:', error);
      setError(isEditing ? 'Error updating the plan. Please check the console for more details.' : 'Error creating the plan. Please check the console for more details.');
    }
  };


  const generateHourOptions = (startHour = 1) => {
    const hours = [];
    for (let i = startHour; i <= 24; i++) {
      const hour = i < 10 ? `0${i}:00:00` : `${i}:00:00`;
      hours.push(hour);
    }
    return hours;
  }; const generateEndTimeOptions = (startTime) => {
    if (!startTime) return generateHourOptions();
    const startHour = parseInt(startTime.split(':')[0], 10);
    return generateHourOptions(startHour + 1);
  };

  // Handle key down for input navigation
  const handleKeyDown = (event) => {
    const focusableElements = Array.from(event.currentTarget.querySelectorAll('input, select, button'));
    const index = focusableElements.indexOf(document.activeElement);
    let nextIndex = index;

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        nextIndex = index === 0 ? focusableElements.length - 1 : index - 1;
      } else {
        nextIndex = index === focusableElements.length - 1 ? 0 : index + 1;
      }
      focusableElements[nextIndex].focus();
      event.preventDefault(); // Prevent default tabbing behavior
    }
  };

  return (
    <div className="create-plan-container" onKeyDown={handleKeyDown}>
      <h2>{isEditing ? 'Edit Plan' : 'Create a New Plan'}</h2>

      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit}>
        {/* Academy Dropdown */}
        <div className="dropdown">
          <label htmlFor="academy_name">Select Sport Center:</label>
          {isEditing ? (
            <input
              type="text"
              id="academy_name"
              value={plan.academy_name}
              readOnly
              className="fixed-input"
            />
          ) : (
            <select
              id="academy_name"
              onChange={(e) => {
                const selectedAcademy = e.target.value;
                handlePlanChange('academy_name', selectedAcademy);
              }}
              value={plan.academy_name}
              required
            >
              <option value="">Select an Academy</option>
              {sportCenters.map(center => (
                <option key={center} value={center}>
                  {center}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Sport Type Field: Dropdown or Fixed Input */}
        <div className="dropdown">
          <label htmlFor="sport_name">Select Sport Type:</label>
          {isEditing ? (
            <input
              type="text"
              id="sport_name"
              value={plan.sport_name}
              readOnly
              className="fixed-input"
            />
          ) : (
            <select
              id="sport_name"
              value={plan.sport_name}
              onChange={(e) => handlePlanChange('sport_name', e.target.value)}
              required
            >
              <option value="">Select a Sport</option>
              {academySportsMap[selectedAcademy] && academySportsMap[selectedAcademy].map((sport, idx) => (
                <option key={idx} value={sport}>
                  {sport}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Sport Type Dropdown */}

        {/* Number of Courts */}
        {(plan.sport_name === 'Badminton' || plan.sport_name === 'TT') && (
          <div className="court-selection">
            <h3>Arena: Select Number of Courts</h3>
            <div className="court-counter">
              <button
                type="button"
                onClick={() => handlePlanChange('max_avail_arenas', Math.max(1, plan.max_avail_arenas - 1))}
                className="counter-btn"
                disabled={plan.max_avail_arenas <= 1}
              >
                <FaMinus />
              </button>
              <span className="court-count">{plan.max_avail_arenas}</span>
              <button
                type="button"
                onClick={() => handlePlanChange('max_avail_arenas', plan.max_avail_arenas + 1)}
                className="counter-btn"
              >
                <FaPlus />
              </button>
            </div>
          </div>
        )}

        {/* GST Input */}
        <div className="input-field">
          <label htmlFor="GST">GST:</label>
          <input
            type="text" // Keep it as text for flexibility
            id="GST"
            value={plan.GST}
            onChange={(e) => {
              const value = e.target.value;
              // Validate input: allow only numbers and one decimal point
              if (/^\d*\.?\d*$/.test(value) || value === '') {
                handlePlanChange('GST', value);
              }
            }}
            required
          />
        </div>

        {/* Weekend Table */}
        <div className="time-price-table weekend-table">
          <h3 className="table-heading">Weekend</h3>
          <table className="price-table">
            <thead>
              <tr>
                <th className="table-header">Start Time</th>
                <th className="table-header">End Time</th>
                <th className="table-header">Price</th>
                <th className="table-header">Actions</th>
              </tr>
            </thead>
            <tbody>
              {weekendData.map((row, index) => (
                <tr key={index} className="table-row">
                  <td className="table-cell">
                    <select
                      className="time-select"
                      value={row.startTime || ''}
                      onChange={(e) => handleWeekendChange(index, 'startTime', e.target.value)} // Correct handler for weekend
                    >
                      <option value="">Select Start Time</option>
                      {generateHourOptions().map((hour) => (
                        <option key={hour} value={hour}>{hour}</option>
                      ))}
                    </select>
                  </td>
                  <td className="table-cell">
                    <select
                      value={row.endTime || ''}
                      onChange={(e) => handleWeekendChange(index, 'endTime', e.target.value)}
                    >
                      <option value="">Select End Time</option>
                      {generateEndTimeOptions(row.startTime).map((hour) => (
                        <option key={hour} value={hour}>{hour}</option>
                      ))}
                    </select>

                  </td>
                  <td className="table-cell">
                    <input
                      type="text"
                      value={row.price || ''}
                      onChange={(e) => handleWeekendChange(index, 'price', e.target.value)} // Handle price
                    />
                  </td>
                  <td className="table-cell">
                    <button type="button" onClick={() => removeWeekendRow(index)}>Remove</button>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
          <button type="button" onClick={addWeekendRow}>Add Row</button>
        </div>

        {/* Weekday Table */}
        <div className="time-price-table weekday-table">
          <h3 className="table-heading">Weekday</h3>
          <table className="price-table">
            <thead>
              <tr>
                <th className="table-header">Start Time</th>
                <th className="table-header">End Time</th>
                <th className="table-header">Price</th>
                <th className="table-header">Actions</th>
              </tr>
            </thead>
            <tbody>
              {weekdayData.map((row, index) => (
                <tr key={index} className="table-row">
                  <td className="table-cell">
                    <select
                      className="time-select"
                      value={row.startTime || ''}
                      onChange={(e) => handleWeekdayChange(index, 'startTime', e.target.value)} // Correct handler for weekday
                    >
                      <option value="">Select Start Time</option>
                      {generateHourOptions().map((hour) => (
                        <option key={hour} value={hour}>{hour}</option>
                      ))}
                    </select>
                  </td>
                  <td className="table-cell">

                    <select
                      value={row.endTime || ''}
                      onChange={(e) => handleWeekdayChange(index, 'endTime', e.target.value)}
                    >
                      <option value="">Select End Time</option>
                      {generateEndTimeOptions(row.startTime).map((hour) => (
                        <option key={hour} value={hour}>{hour}</option>
                      ))}
                    </select>
                  </td>
                  <td className="table-cell">
                    <input
                      type="text"
                      value={row.price || ''}
                      onChange={(e) => handleWeekdayChange(index, 'price', e.target.value)} // Handle price
                    />
                  </td>
                  <td className="table-cell">
                    <button type="button" onClick={() => removeWeekdayRow(index)}>Remove</button>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
          <button type="button" onClick={addWeekdayRow}>Add Row</button>
        </div>



        <button type="submit" className="submit-button">{isEditing ? 'Update Plan' : 'Create Plan'}</button>
      </form>
    </div>
  );
};

export default CreatePlan;